<template>
  <div
      class="w-100v h-100v d-flex justify-center align-center text-h1 font-weight-light"
  >
    404
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>